import * as React from "react";

import PageLayout from "../../components/PageLayout";
import Header from "../../components/Header";
import FeaturesSection from "../../sections/Features/FeaturesSection";
import FeaturesList from "../../sections/Features/FeaturesList";
import Footer from "../../components/Footer";

const Features = () => {
  return (
    <>
      <Header />
      <PageLayout title="Features" >
        <FeaturesSection />
        <FeaturesList />
        <Footer />
      </PageLayout>
    </>
  );
};

export default Features;
